import React , { useMemo } from "react";
import { Button, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { selectExternalUserTasks } from './externalUserTasksSlice';
import { unallocateSurveyExternal } from "../allocatesurvey/unallocateSurveyExternal";
import { listExternalUserTasks } from "./externalUserTasksSlice"
import { selectUser } from '../auth/authSlice';
import { Container, Card, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { CardBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSort, faInfoCircle, faEyeSlash, faFileAlt, faEye } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.min.css"
// import { useSelector } from "react-redux";
// import { selectUser } from "../auth/authSlice";
import TableContainer from "../allocatedsurveydata/TableContainer"
import { FileUploads } from "../allocatedsurveydata/FileUploads";

export const ExternalUserTasksAdmin = (props) => {
  
  const dispatch = useDispatch();
  const externalusersurveys = useSelector(selectExternalUserTasks);
  let surveys = externalusersurveys
  const user = useSelector(selectUser);
  let allSurveys =[];

  function byKeyStartDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINStartDate) {
          return arr[i].PINStartDate
        }
        else {
          return key.survey_start_date
        }
      }
    }
  }

  function byKeyEndDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINEndDate) {
          return arr[i].PINEndDate
        }
        else {
          return key.survey_end_date
        }
      }
    }
  }

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  if (surveys && surveys.length > 0) {
    surveys.map((surveyRow, key) => {

      return allSurveys.push({
        id: surveyRow.PK,
        title: surveyRow.author_title,
        start_date: dateFormat(byKeyStartDate(surveys, surveyRow)),
        end_date: dateFormat(byKeyEndDate(surveys, surveyRow)),
        unallocate: <Button
        onClick={() => {
           dispatch(unallocateSurveyExternal(props.username, surveyRow.PK.split('SURVEY#')[1], surveyRow.author_title))
           dispatch(listExternalUserTasks(props.username, user.cso_identifier))
           }}
         variant="danger"
         >
         Unallocate Survey
       </Button>,
        file_upload: surveyRow.fileUploads
      })
    })
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Survey ID',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true
      },{
        Header: 'Survey Title',
        accessor: 'title',
        disableFilters: true,
        disableSortBy: true
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by start date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Start Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
        resizable: false,
        accessor: 'start_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by end date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              End Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>),
        sortable: true,
        resizable: false,
        accessor: 'end_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      },{
        Header: 'Unallocate',
        accessor: 'unallocate',
        disableFilters: true,
        disableSortBy: true,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Unallocate survey for external user
                </Popover.Content>
              </Popover>
            )} >
            <div>
            Unallocate
            </div>
          </OverlayTrigger>)

      }, {
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  View details of previously uploaded files.
                </Popover.Content>
              </Popover>
            )} >
            <div style={{ textAlign: "center" }}>
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
          </OverlayTrigger>),
        id: 'expander', // 'id' is required
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }} >
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <FontAwesomeIcon icon={faEyeSlash} title="Toggle Row Expanded" /> : <FontAwesomeIcon icon={faEye} title="Toggle Row Hidden" />}
            </span>
          </div>
        )
      }
    ],
    [externalusersurveys, user.cso_identifier, user.username]
  )

  const renderRowSubComponent = row => {

    const uploads = surveys.filter(obj => {
      return  (obj.SK === row.original.id) && obj.fileUploads && obj.fileUploads.length > 0
      
    })

    return (
      uploads.length === 0 ? (
        
        <div style={{ margin: "0 auto", textAlign: "center" }}> No file upload data available </div>
      ) : (
        <Card>
          <CardBody>
            <FileUploads uploads={uploads[0].fileUploads} />
          </CardBody>
        </Card>
      )
    )
  }

  return (    
    // externalusersurveys ? (
    //   <Table responsive striped bordered hover size="sm">
    //       <thead>
    //         <tr style={{ textAlign: "center" }}>
    //           <td><b>Survey ID</b></td>
    //           <td><b>Survey Title</b></td>
    //           <td><b>Start Date</b></td>
    //           <td><b>End Date</b></td>
    //           <td><b>Unallocate</b></td>
    //           <td><b>Eye</b></td>
    //         </tr>
    //       </thead>
    //     {externalusersurveys.length > 0 ? (
    //       <tbody>
    //         {externalusersurveys.map((surveyRow, key) => {

    //           return (
    //               <tr key={key} style={{ textAlign: "center"}}>
    //                 <td>{surveyRow.PK}</td>
    //                 <td>{surveyRow.author_title}</td>
    //                 <td>{dateFormat(byKeyStartDate(externalusersurveys, surveyRow))}</td> 
    //                 <td>{dateFormat(byKeyEndDate(externalusersurveys, surveyRow))}</td> 
    //                 <td>
    //                     <Button
    //                      onClick={() => {
    //                         dispatch(unallocateSurveyExternal(props.username, surveyRow.PK.split('SURVEY#')[1], surveyRow.author_title))
    //                         dispatch(listExternalUserTasks(props.username, user.cso_identifier))
    //                         }}
    //                       variant="danger"
    //                       >
    //                       Unallocate Survey
    //                     </Button>
    //                 </td>
    //                 <td>{surveyRow.fileUploads[0].Filename}</td>
    //                 {/* <td>{}</td> */}
    //               </tr>
    //           )
    //         })}
    //       </tbody>
    //     ) : (
    //       <div>There are no surveys assigned to this user.</div>
    //     )}
    //   </Table>
    // ) : (
    //   <div>&nbsp; Loading...</div>
    // )
    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Container fluid className='pt-5'>
        <Card>

          <Card.Body>
            <TableContainer columns={columns} data={allSurveys} renderRowSubComponent={renderRowSubComponent} />
          </Card.Body>
        </Card>
      </Container>
    </Col>
  );
};


