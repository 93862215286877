import React from "react";
import Table from 'react-bootstrap/Table'
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from '../auth/authSlice';
import { Link } from 'react-router-dom';
import { selectUsers, listUsers } from '../userlist/usersListSlice';
import { Button } from 'react-bootstrap';
import {makeAdminUser} from '../makeadmin/makeAdminSlice';
import {revokeAdminUser} from '../revokeadmin/revokeAdminSlice';

export const UsersList = () => {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userslist = useSelector(selectUsers);

  React.useEffect(() => {

    dispatch(listUsers(user.cso_identifier));


  }, [user])

  return (

    userslist && userslist.length > 0 ? (
      
    <Table responsive striped bordered hover size="sm">
        <thead>
          <tr>
            <td><b>Username</b></td>
            <td><b>Email</b></td>
            <td><b>Status</b></td>
            <td><b>User Details</b></td>
          </tr>
        </thead>
        {userslist ? (
          <tbody>
            {userslist.map((userRow, key) => {
              return (
                <tr key={key}>
                  <td>{userRow.Username}</td>
                  <td>{userRow.Attributes.find(attr => attr.Name === "email").Value}</td>
                  <td>{userRow.UserStatus}</td>
                  <td>
                    <Link to ={`/home/users/details/${userRow.Username}`} >
                        <Button variant="secondary">View More Details</Button>
                    </Link>
                  </td>
                </tr>
                )
            })}
          </tbody>
        ) : (
          <tbody>
            <div>Loading...</div>
          </tbody>
        )}
    </Table>
     ) : (
      <div>&nbsp;There was a connection issue and we could not find any users. Please refresh.</div>
    )

  );
};

export default UsersList;
