import React, { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link, useHistory } from "react-router-dom";
import { Form, Col, Container, Row, Button, Alert, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { allocateSurveyExternal, resetAllocatedExternalMessage, selectAllocatedExternalMessage } from '../allocatesurvey/allocateSurveyExternalSlice';
import { selectSurveys } from "../surveylist/surveyListSlice";
import { selectUser } from '../auth/authSlice';
import { ExternalUserTasksAdmin } from './ExternalUserTasksAdmin';
import { RevokeAffiliation } from '../revokeaffiliation/RevokeAffiliation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { listExternalUserTasks } from "./externalUserTasksSlice"
import { selectExternalUserTasks } from './externalUserTasksSlice';

export const ExternalSurveyAllocation = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const history = useHistory()
  const username = location.state?.username
  const user = useSelector(selectUser);
  const surveys = useSelector(selectSurveys);
  const externalusersurveys = useSelector(selectExternalUserTasks);
  let surveyarray = []


  const success = useSelector(selectAllocatedExternalMessage)

  const [surveyState, setSurveyState] = useState('')
  let filteredArray = [];
  
  const filteredSurveys = (arr1, arr2, key) =>{
    const arr2keys = arr2.map(obj => obj[key]);
    return arr1.filter(obj => !arr2keys.includes(obj[key]));
  }
  const key = 'author_title'

  filteredArray = filteredSurveys(surveys,externalusersurveys , key);

  const getSurvey = (e) => {
    const surveyValue = e.target.value;
    setSurveyState(surveyValue)
    };

  async function surveyArray(surveys)
  {
    for( var i=0, L=surveys.length; i<L; i++ )
    {
        surveyarray.push(surveys[i])
    }
  }
  

  surveyArray(surveys)

  return (
    <div className='feature-wrapper pt-5 pb-5 mt-5 mt-lg-0'>
        <Container>
            <Card>
              <Card.Header style={{backgroundColor: "#004F50", color: "white"}}>
                <h3 style={{textAlign:'left', float:'left'}}>External User Actions</h3>
                <h5 style={{textAlign:'right', float:'right'}}><FontAwesomeIcon style={{cursor:'pointer'}} onClick={() => dispatch(listExternalUserTasks(username, user.cso_identifier))} icon={faSync}/></h5>
              </Card.Header>
              <Card.Body>
              <ExternalUserTasksAdmin username={username}/>
                  <Form>
                  <Form.Group as={Row}>
                      <Form.Label column sm="8">
                      External User
                      </Form.Label>
                      <Col md="6" sm="12" lg="6">
                      <Form.Control type="text" readOnly defaultValue={username}/>
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                      {/* <Form.Label column sm="8">
                          Available surveys
                      </Form.Label> */}
                      <Col md="6" sm="12" lg="6">
                          <Form.Control 
                              as="select"
                              className="mr-sm-2"
                              id="survey_id"
                              custom
                              onChange={getSurvey}
                              value={surveyState}>

                                      <option value={0}>Select survey to allocate..</option>
                              {filteredArray ? (
                                  filteredArray.map((filteredArray, key) => {
                                  return (
                                      <option value={key+1}>{filteredArray.author_title}</option>
                                      )
                                  }) ) : ("")}
                          </Form.Control>
                      </Col>
                  </Form.Group>
                      <div style={{float: "right"}} className="pt-2 pr-3">
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Select a survey from the drop down to allocate a survey
                            </Tooltip>
                          )}
                          placement="bottom"
                        >
                          <Button 
                              variant="info"
                              disabled={(surveyState) < 1} 
                              onClick={() => dispatch(allocateSurveyExternal(username, filteredArray[surveyState-1].PK.split('#')[1], filteredArray[surveyState-1].author_title))}
                              >
                              Allocate External User
                          </Button>
                        </OverlayTrigger>
                      <div style={{float: "right"}} className="pl-3 pr-3">
                            <RevokeAffiliation username={username} />
                      </div>
                      </div>
                      <div>
                        <Alert show={success} variant="success">
                          <Alert.Heading>Thanks!</Alert.Heading>
                            <p>
                              We have sent an email to this user informing them of this allocation.
                            </p>
                            <div>
                              <Button onClick={() => {
                                  dispatch(resetAllocatedExternalMessage())
                                  dispatch(listExternalUserTasks(username, user.cso_identifier))
                                  setSurveyState('')
                                  }}
                                  variant="success">
                                  Allocate another survey
                              </Button>
                            </div>
                              <div className="pt-2 pr-3">
                                  <Link to={{ pathname: "/home/external" }}>
                                      <Button onClick={() => {
                                          dispatch(resetAllocatedExternalMessage())
                                          setSurveyState('')
                                          }}
                                          variant="danger">
                                          Back to user management
                                      </Button>
                                  </Link>
                            </div>
                        </Alert>
                      </div>
                  </Form>
              </Card.Body>
            </Card>
            <div style={{float: "right"}} className="pt-2 pr-2">
            <Button
              onClick={() => history.goBack()}
              variant='info'
              >Back
            </Button>
          </div>
        </Container>
    </div>
  );
}
