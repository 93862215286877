import React, {useState, useEffect, useRef} from 'react';
import { Form, Col, Container, Row, Card, Button } from 'react-bootstrap';
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unlistUsersSurveyData } from './ManageUsersSurveyDataSlice';
import { unlistSurveyUsers } from '../managesurveyusers/ManageSurveyUsersSlice';
import { UsersSurveyData } from './ManageUsersSurveyData';
import API from '../../services/api';
import { SURVEYS_API_URL, USERS_API_URL } from '../../config/service';
import { makeAdminUser } from '../makeadmin/makeAdminSlice';
import { revokeAdminUser } from '../revokeadmin/revokeAdminSlice';
import { selectUser } from '../auth/authSlice';
import { selectUsers } from '../userlist/usersListSlice';

export const ManageUserDetails = () => {

    const [surveyState, setSurveyState] = useState({});
    const [groupState, setGroupState] = useState({});
    const [loadState, setLoadState] = useState(true);
    const user = useSelector(selectUser);
    const usersList = useSelector(selectUsers);
    const history = useHistory()
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedUser = location.pathname.split('details/')[1];
    const [refresh, setRefresh] = useState(Date.now());
    const forceRefresh = () => setRefresh(Date.now());

    function byKey(arr, key) {
        for ( var i=0, L=arr.length; i<L; i++ ) {
          if ( arr[i].Username === key ) {
            return arr[i].Attributes[1].Value;
          }
        }
      }

      const groupStateRef = useRef();
      const surveyStateRef = useRef();

      async function loadAssignedData() {
        try {
            const groupResponse = await API.get(`${USERS_API_URL}/v1/users/groups/${selectedUser}`);
            setGroupState(groupResponse.data);
    
            const surveyResponse = await API.get(`${SURVEYS_API_URL}/v1/surveys/users/allocated/${selectedUser}`);
            setSurveyState(surveyResponse.data.Items);
    
            dispatch(unlistUsersSurveyData());
            setLoadState(false);
        } catch (error) {
            console.error("Error loading assigned data:", error);
        }
    }
    
      
      useEffect(() => {
          loadAssignedData();
          dispatch(unlistSurveyUsers());
      }, [selectedUser, refresh]); 
      

    function userAdmin(groups){
        if(groups.includes("BUSINESS_ADMINS") || groups.includes("CSO_ADMINS"))
        {
            return "True";
        }
        else return "False"   
    }

    return (
        <div>
        <Container className="pt-5">
            <Card>
            <Card.Header 
                style={{backgroundColor: "#FBAA34", color: "white"}}><h3>User Details</h3>
            </Card.Header>
            <Card.Body>
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="8">Username</Form.Label>
                    <Col sm="10">
                    <Form.Control type="text" readOnly defaultValue={selectedUser}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="8">Email</Form.Label>
                    <Col sm="10">
                    <Form.Control type="text" readOnly defaultValue={byKey(usersList, selectedUser)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="8">Account Admin</Form.Label>
                    {groupState.length > 0 ? (
                    <Col sm="10">                    
                    <Form.Control type="text" readOnly value={userAdmin(groupState)}/><br></br>
                    {userAdmin(groupState) === "False" ? (
                        <div>
                            
                            <Button
                                disabled={userAdmin(groupState) === "True"}
                                onClick={async () => {                                    
                                    await dispatch(makeAdminUser(selectedUser));
                                    await loadAssignedData();
                                    forceRefresh(); 
                                }}
                                variant="info"
                            >
                                Make Admin
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button
                                disabled={userAdmin(groupState) === "False" || selectedUser === user.username}
                                onClick={async () => {
                                    await dispatch(revokeAdminUser(selectedUser));
                                    await loadAssignedData();
                                    forceRefresh(); 
                                }}
                                variant="danger"
                            >
                                Revoke Admin
                            </Button>
                        </div>
                    )}

                    <div className="pt-2 pr-2">
                        <Button
                        onClick={() => history.goBack()}
                        variant='info'
                        >Back to Home
                        </Button>
                    </div>
                    </Col>
 
                    ) : (<div>Loading...</div>)}

                </Form.Group>
                
                <Form.Group as={Row}>
                    <Form.Label column sm="8">Allocated Surveys</Form.Label>
                    <Col sm="10">
                    {loadState === false ? (

                        <UsersSurveyData surveys={surveyState} user={selectedUser}/>

                    ) : (<div>Loading...</div>)} 
                    </Col>
                </Form.Group>
            </Form>
            </Card.Body>
            </Card>
        </Container>
        </div> 
    );
}