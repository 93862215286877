import { createSlice } from '@reduxjs/toolkit';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';
import { showError } from '../messages/messagesSlice';

export const removeSlice = createSlice({
  name: 'removeaccount',
  initialState: {
    triggerRemoveAccount: false
  },
  reducers: {
    deleteAccount: (state, action) => {
        state.triggerRemoveAccount = true;
    },
    deleteAccountTriggered: (state, action) => {
      state.triggerRemoveAccount = false;
    }
  },
});

export const deleteUserRecordsDynamo = (username) => dispatch =>{
  try {
    API.delete(`${SURVEYS_API_URL}/v1/surveys/userrecords/remove/${username}`)
    .then(async function(response) {
        if(response.data)
        {
            console.log(response.data)
        }
    });
}
catch(error) {
    dispatch(showError({ "message": `Survey users retrieval failed. ${error.message}` }));
};
}
export const { deleteAccount, deleteAccountTriggered } = removeSlice.actions;

export const selectTriggerRemoveAccount = state => state.removeaccount.triggerRemoveAccount;

export default removeSlice.reducer;
