import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { G_RECAPTCHA_CLIENT_ID } from "../../config/extra";
import { Form, Col, Container, Row, Button, Alert, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { selectUser } from '../auth/authSlice';
import { selectSendMessage, sendMessageAction } from '../sendmessage/sendMessageSlice';

export function Contacts() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { sent } = useSelector(selectSendMessage);
  const recaptchaRef = useRef(null);

  const [fields, setFields] = useState({
    formComment: "",
    formReplyEmail: user.email,
    formRecaptcha: "",
  });
  const [showThankYou, setShowThankYou] = useState(false);

  const handleFieldChange = (event) => {
    const { id, value } = event.target;
    setFields((prevFields) => ({
      ...prevFields,
      [id]: value,
    }));
  };

  const handleRecaptcha = (value) => {
    setFields((prevFields) => ({
      ...prevFields,
      formRecaptcha: value,
    }));
  };

  const resetForm = () => {
    setFields({
      ...fields,
      formComment: "",
      formRecaptcha: "",
    });
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleSendMessage = () => {
    dispatch(sendMessageAction(fields.formComment, fields.formReplyEmail, fields.formRecaptcha));
    setShowThankYou(true);

    setTimeout(() => {
      setShowThankYou(false);
      resetForm();
    }, 5000);
  };

  const tooltip = (
    <Tooltip id="tooltip">Please verify and provide a message you want to convey.</Tooltip>
  );

  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{ backgroundColor: "#FBAA34", color: "white" }}>
            <h3>Contact us</h3>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Have you got an enquiry on a survey or a management issue? <br />
              Drop a message to the CSO team and you will be contacted privately.
            </Card.Text>
            <Form>
              <Form.Group as={Row} controlId="formCSOIdentifier">
                <Form.Label column sm="8">
                  Your CSO Identifier
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" readOnly defaultValue={user.cso_identifier} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formReplyEmail">
                <Form.Label column sm="8">
                  Reply Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    readOnly
                    defaultValue={user.email}
                    onChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formComment">
                <Form.Label column sm="8">
                  Comment *
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={fields.formComment}
                    onChange={handleFieldChange}
                    placeholder="I would like to be contacted by CSO in regard to..."
                    disabled={showThankYou}
                    maxLength={2000}
                  />
                </Col>
              </Form.Group>
              <Form.Text className="text-muted">
                {`${fields.formComment.length}/2000 characters used`}
              </Form.Text>
              <Form.Group as={Row} controlId="formRecaptcha" className={showThankYou ? "d-none" : ""}>
                <Col sm="12" className="mx-auto">
                  <div style={{ width: "164px" }} className="mx-auto">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={G_RECAPTCHA_CLIENT_ID}
                      onChange={handleRecaptcha}
                      size="compact"
                    />
                  </div>
                </Col>
              </Form.Group>

              <Alert show={showThankYou} variant="success">
                <Alert.Heading>Thanks!</Alert.Heading>
                <p>
                We have successfully received your message and we will be contacting you shortly
                  through your preferred contact method.
                </p>
              </Alert>
              <div style={{ float: "right" }} className="pt-2 pr-2">
                <OverlayTrigger placement="top" overlay={tooltip} trigger="hover">
                  <div className={`d-inline ${showThankYou ? "d-none" : ""}`}>
                    <Button
                      disabled={!fields.formComment || !fields.formRecaptcha}
                      onClick={handleSendMessage}
                      variant="info"
                    >
                      Send Message
                    </Button>
                  </div>
                </OverlayTrigger>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
