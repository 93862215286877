import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Container, Row, Button, Alert, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { selectUser } from '../auth/authSlice';
import { registerBusinessUser, resetUIMessage, selectSuccess } from './userManagementSlice';
import { UsersList } from '../userlist/usersList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export function UserManagement() {
  const user = useSelector(selectUser);
  const success = useSelector(selectSuccess);
  const [usernameState, setUsernameState] = useState('');
  const [emailState, setEmailState] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true); 
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [error, setError] = useState('');
  const [usererror, setuserError] = useState('');

  const dispatch = useDispatch();

  const getUsername = (e) => {
    const usernameValue = e.target.value;
    setUsernameState(usernameValue);
    const uservalidationresult = (validateUsername(usernameValue));
    setIsUsernameValid(uservalidationresult.isuserValid);
    setuserError(uservalidationresult.usererror);
  };

  const getEmail = (e) => {
    const emailValue = e.target.value;
    setEmailState(emailValue);
    const validationResult = validateEmail(emailValue);
    setIsEmailValid(validationResult.isValid);
    setError(validationResult.error); 
  };

  const validateEmail = (email) => {
    // Rule 1: The email should not begin or end with a dot.
    if (email.startsWith('.') || email.endsWith('.')) {
      return { isValid: false, error: "Email should not start or end with a dot." };
    }
  
    // Rule 2: The domain part and local part must be there
    const [localPart, domainPart] = email.split('@');
    if (!localPart || !domainPart) {
      return { isValid: false, error: "Email must contain a local and domain part separated by '@'." };
    }
  
    // Rule 3: A dot should not immediately follow the '@' symbol.
    if (domainPart.startsWith('.') || domainPart.endsWith('.')) {
      return { isValid: false, error: "The domain part should not start with a dot after '@'." };
    }
  
    // Rule 4: Consecutive dots (..) are not allowed in the local part.
    if (localPart.includes('..')) {
      return { isValid: false, error: "The local part should not contain consecutive dots (..)." };
    }

    // Rule 5: . not allowed in the end of local part.
    if (localPart.endsWith('.')) {
      return { isValid: false, error: "The local part should not end with a dot." };
    }

    // const domainLevels = domainPart.split(".");
    // if (domainLevels.length > 3) {
    //   return { isValid: false, error: "The domain part must have one or two subdomain levels (e.g., 'example.com or example.com.ie')." };
    // }
    
    // Rule 6: Standard check.
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~áéíóúàèìòùċḋṁṡṫ-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
    if (!emailRegex.test(email)) {
      return { isValid: false, error: "Please enter valid email format." };
    }
  
    // If all checks pass
    return { isValid: true, error: null };
  };

  const validateUsername = (username) => {
    const usernameRegex = /^[^<>:"/\\|?*.]+$/;
    if (!usernameRegex.test(username)){
      return { isuserValid: false, usererror: "Username cannot contain the following characters: /\":\\|?* . Please enter a different username and try again"};
    }
    
    if (username.length > 32){
    return { isuserValid: false, usererror: "Username cannot be more than 32 characters . Please enter a different username and try again"};
    }
    return { isuserValid: true, usererror: null};
  };

  const userType = user.groups && user.groups.toString();
  let admin;
  if (typeof userType !== "undefined") {
    const strIndex = userType.indexOf('ADMINS');
    admin = strIndex !== -1;
  }

  if (admin) {
    return (
      <div style={{ padding: "3rem 2rem 0 2rem" }}>
        <Row>
          <Col>
            <Container>
              <Card>
                <Card.Header style={{ backgroundColor: "#FBAA34", color: "white" }}>
                  <h3 style={{ textAlign: 'left', float: 'left' }}>Register User</h3>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 300 }}
                    overlay={(
                      <Popover id="popover-basic">
                        <Popover.Content>
                          <div>Please use the form to register a business user <b>belonging to your organisation</b>. This user will receive temporary credentials and sign-up instructions via email. If you want to affiliate an external user with your organisation, please navigate to the <b>External User Management</b> tab.</div>
                        </Popover.Content>
                      </Popover>
                    )}
                  >
                    <h5 style={{ textAlign: 'right', float: 'right' }}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </h5>
                  </OverlayTrigger>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group as={Row} controlId="formTextCSOIdentifier">
                      <Form.Label column sm="8">
                        CSO Identifier
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control type="text" readOnly defaultValue={user.cso_identifier} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="username">
                      <Form.Label column sm="8">
                        Username
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          onChange={getUsername}
                          value={usernameState}
                          isInvalid={!isUsernameValid && usernameState !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                          {usererror}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="userEmail">
                      <Form.Label column sm="8">
                        Email Address
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          type="text"
                          onChange={getEmail}
                          value={emailState}
                          isInvalid={!isEmailValid && emailState !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                          {error}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <div style={{ float: "right" }} className="pt-2 pr-3">
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Please provide username and an email address to complete the registration of an internal user.
                            </Tooltip>
                          )}
                          placement="bottom"
                        >
                      <Button
                        disabled={!isEmailValid || emailState === "" || !isUsernameValid || usernameState === ""}
                        onClick={() => {
                          dispatch(registerBusinessUser(emailState, usernameState));
                        }}
                        variant="info"
                      >
                        Register Internal User
                      </Button>
                      </OverlayTrigger>
                    </div>
                    <div>
                      <Alert show={success} variant="success">
                        <Alert.Heading>Thanks!</Alert.Heading>
                        <p>
                          We have sent an email to this user with temporary login details and instructions. These details will expire in 7 days.
                        </p>
                        <div>
                          <Button
                            onClick={() => {
                              dispatch(resetUIMessage());
                              setUsernameState('');
                              setEmailState('');
                              setIsEmailValid(true); // Reset email validation state
                            }}
                            variant="success"
                          >
                            Register another user
                          </Button>
                        </div>
                      </Alert>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Container>
          </Col>
          <Col>
            <Container className="user-action">
              <Card>
                <Card.Header style={{ backgroundColor: "#FBAA34", color: "white" }}>
                  <h3 style={{ textAlign: 'left', float: 'left' }}>Other User Actions</h3>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 150, hide: 300 }}
                    overlay={(
                      <Popover id="popover-basic">
                        <Popover.Content>
                          Allocate internal users to surveys, change access permissions, and view currently allocated surveys.
                        </Popover.Content>
                      </Popover>
                    )}
                  >
                    <h5 style={{ textAlign: 'right', float: 'right' }}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </h5>
                  </OverlayTrigger>
                </Card.Header>
                <Card.Body>
                  <div><UsersList /></div>
                </Card.Body>
              </Card>
            </Container>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <Redirect to="/homepage" />
    );
  }
}

