import React from "react";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { selectSurveys } from "../surveylist/surveyListSlice";
import { listUsersSurveyData, selectUsersSurveyData } from "./ManageUsersSurveyDataSlice";

export const UsersSurveyData = (props) => {

  const userssurveydata = useSelector(selectUsersSurveyData);
  const dispatch = useDispatch()
  const userssurveys = props.surveys
  const surveys = useSelector(selectSurveys);

  React.useEffect(() => {
    const fetchData = async () => {
        for (const surveyRow of userssurveys) {
            await dispatch(listUsersSurveyData(surveyRow.SK.split('#')[1]));
        }
    };
    fetchData();
}, [props.surveys, props.user, dispatch]);


  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }
  
  
  function byKeySubmissionStatus(arr, key) {
    for ( var i=0, L=arr.length; i<L; i++ ) {
      if ( arr[i].SK === key ) {
        if (arr[i].PIN === "NONEQ")
        {
          return '-'
        }
          if (arr[i].eQSubmissionStatus)
          {
            return "Submitted"
          }
          else return "Not Submitted"
      }
    }
  }
  
  return (
    userssurveys && userssurveys.length > 0  ? (
      <Table responsive striped bordered hover size="sm">
          <thead>
            <tr>
              <td><b>Survey Title</b></td>
              <td><b>Survey Start Date</b></td>
              <td><b>Survey End Date</b></td>
              <td><b>Submission Status</b></td>
              <td><b>Manage User Allocation</b></td>
            </tr>
          </thead>
        {userssurveydata ? (
          <tbody>
            {userssurveydata.map((surveyRow, key) => {

              return (
                  <tr key={key}>
                    <td>{surveyRow.author_title}</td>
                    <td>{dateFormat(surveyRow.survey_start_date)}</td>
                    <td>{dateFormat(surveyRow.survey_end_date)}</td>
                    <td>{byKeySubmissionStatus(surveys, surveyRow.SK)}</td>
                    <td>
                      <Link to ={`/home/users/manage/${surveyRow.survey_id}`} >
                          <Button variant="secondary">Manage Users</Button>
                      </Link>
                    </td>
                  </tr>
              )
            })}
          </tbody>
        ) : (
          <div>Loading...</div>
        )}
      </Table>
    ) : (
      <div>&nbsp;No surveys have been allocated to this user.</div>
    )
  );
};

export default UsersSurveyData;
