import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { G_RECAPTCHA_CLIENT_ID } from "../../config/extra";
import { Form, Col, Container, Row, Button, Alert, Card } from 'react-bootstrap';
import { selectSendMessage, sendMessageAction, resetMessageForm } from '../sendmessage/sendExternalMessageSlice';

export const ContactExternal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sentExt } = useSelector(selectSendMessage);
  const location = useLocation();
  const toEmail = location.state?.email.trim();

  const recaptchaRef = useRef(null);

  React.useEffect(() => {
    dispatch(resetMessageForm());
  }, [toEmail, dispatch]);

  // State for form fields
  const [fields, setFields] = useState({
    formComment: "",
    formReplyEmail: toEmail,
    formRecaptcha: ""
  });

  // Handle input changes
  const handleFieldChange = (e) => {
    setFields({
      ...fields,
      [e.target.id]: e.target.value
    });
  };

  // Handle ReCAPTCHA response
  const handleRecaptcha = (value) => {
    setFields({
      ...fields,
      formRecaptcha: value
    });
  };

  // Reset form and reCAPTCHA
  const resetForm = () => {
    setFields({
      formComment: "",
      formReplyEmail: fields.formReplyEmail,
      formRecaptcha: ""
    });
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  // Handle send message action
  const handleSendMessage = () => {
    dispatch(sendMessageAction(fields.formComment, fields.formReplyEmail.trim(), fields.formRecaptcha));

    // Reset the form after 5 seconds
    setTimeout(() => {
      resetForm();
    }, 5000);
  };

  return (
    <div>
      <Container className="pt-5">
        <Card>
          <Card.Header style={{ backgroundColor: "#1D345C", color: "white" }}>
            <h3>Send Email Request</h3>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Send email request to an external user to sign up to the platform.
              Sign-up instructions will be included with this email.
              You must include a message in the comment box and complete the ReCAPTCHA verification in order to send the request.
            </Card.Text>
            <Form>
              <Form.Group as={Row} controlId="formReplyEmail">
                <Form.Label column sm="8">External User Email</Form.Label>
                <Col sm="10">
                  <Form.Control 
                    type="text"
                    defaultValue={toEmail}
                    onChange={handleFieldChange}
                    disabled={true}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formComment">
                <Form.Label column sm="8">Comment</Form.Label>
                <Col sm="10">
                  <Form.Control 
                    as="textarea"
                    rows={3}
                    onChange={handleFieldChange}
                    placeholder="You must add a comment here in order to submit this request..."
                    disabled={sentExt}
                    value={fields.formComment}
                    maxLength={2000}
                  />
                </Col>
              </Form.Group>
              <Form.Text className="text-muted">
                {`${fields.formComment.length}/2000 characters used`}
              </Form.Text>
              <Form.Group as={Row} controlId="formRecaptcha" className={sentExt ? "d-none" : ""}>
                <Col sm="12" className="mx-auto">
                  <div style={{ width: "164px" }} className="mx-auto">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={G_RECAPTCHA_CLIENT_ID}
                      onChange={handleRecaptcha}
                      size="compact"
                    />
                  </div>
                </Col>
              </Form.Group>

              <Alert show={sentExt === true} variant="success">
                <Alert.Heading>Thanks!</Alert.Heading>
                <p>Your request has been sent.</p>
              </Alert>

              <div style={{ float: "right" }} className="pt-2 pl-2">
                <Button onClick={() => history.goBack()} variant="danger">
                  Back
                </Button>
              </div>

              <div
                className="pt-2 pr-2"
                style={
                  !fields.formComment || !fields.formRecaptcha
                    ? { float: "right", opacity: "0.25", cursor: "pointer" }
                    : { float: "right" }
                }
              >
                <Button
                  className={sentExt ? "d-none" : ""}
                  disabled={!fields.formComment || !fields.formRecaptcha}
                  onClick={handleSendMessage}
                  variant="info"
                >
                  Send Message
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
