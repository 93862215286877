import React, { useMemo } from 'react';
import { Container, Card, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../allocatedsurveydata/TableContainer"
import { useSelector, useDispatch } from "react-redux";
import { selectCsoAllUsers, csoAllUsers } from "../csoallusers/csoAllUsersSlice";

export const CsoAllUsers = () => {

  const dispatch = useDispatch();
  let users = useSelector(selectCsoAllUsers); 
  let allUsers = []

  users = users.Users
  // console.log(users)

  React.useEffect(() => {
    dispatch(csoAllUsers())
  }, [dispatch]);

  function byKey(arr, key, index) {
    for ( var i=0, L=arr.length; i<L; i++ ) {
      if ( arr[i].Username === key ) {
        for (var j=0, K=arr[i].Attributes.length; j<K; j++)
        {
            if ( arr[i].Attributes[j].Name === index)
            {
                return arr[i].Attributes[j].Value;
            }
        }
        return ''
      }
    }
  }

  if (users && users.length > 0) {
    users.map((userRow, key) => {

      return allUsers.push({
        id: userRow.Username,
        name: byKey(users, userRow.Username, 'name'),
        cso_id: byKey(users, userRow.Username, 'custom:cso_identifier'),
        email: byKey(users, userRow.Username, 'email'),
        company: byKey(users, userRow.Username, 'custom:company_name')
        // end_date: dateFormat(byKeyEndDate(users, userRow)),
        // download: userRow.survey_id,
        // upload: userRow.survey_id
      })
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: () => (
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 300 }}
              overlay={(
                <Popover id="popover-basic">
                  <Popover.Content>
                    Sort users by username
                  </Popover.Content>
                </Popover>
              )} >
              <div>
                Username <FontAwesomeIcon icon={faSort} />
              </div>
            </OverlayTrigger>
          ),
        accessor: 'id',
        disableFilters: false,
        disableSortBy: false
      }, {
        Header: () => (
            <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
                <Popover id="popover-basic">
                <Popover.Content>
                    Sort users by name
                </Popover.Content>
                </Popover>
            )} >
            <div>
                Name <FontAwesomeIcon icon={faSort} />
            </div>
            </OverlayTrigger>
        ),
        accessor: 'name',
        disableFilters: false,
        disableSortBy: false,
        Cell: props => {
            return (
            <div style={{ textAlign: "left" }} >
                {props.value}
            </div>
            )
        }
      }, {
        disableFilters: false,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort users by email
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Email <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
        resizable: false,
        accessor: 'email',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      },{
        disableFilters: false,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort users by CSO ID
                </Popover.Content>
              </Popover>
            )} >
            <div>
              CSO ID <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
        resizable: false,
        accessor: 'cso_id',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      },{
        disableFilters: false,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort users by company
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Company <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
        resizable: false,
        accessor: 'company',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      }
    //   {
    //     disableFilters: true,
    //     disableSortBy: false,
    //     Header: () => (
    //       <OverlayTrigger
    //         placement="top"
    //         delay={{ show: 150, hide: 300 }}
    //         overlay={(
    //           <Popover id="popover-basic">
    //             <Popover.Content>
    //               Sort users by end date
    //             </Popover.Content>
    //           </Popover>
    //         )} >
    //         <div>
    //           End Date <FontAwesomeIcon icon={faSort} />
    //         </div>
    //       </OverlayTrigger>),
    //     sortable: true,
    //     resizable: false,
    //     accessor: 'end_date',
    //     Cell: props => {
    //       return (
    //         <div style={{ textAlign: "left" }} >
    //           {props.value}
    //         </div>
    //       )
    //     }
    //   },
    //   {
    //     disableFilters: true,
    //     disableSortBy: true,
    //     Header: () => (
    //       <OverlayTrigger
    //         placement="top"
    //         delay={{ show: 150, hide: 300 }}
    //         overlay={(
    //           <Popover id="popover-basic">
    //             <Popover.Content>
    //               Use this button to download templates required to fill out the survey.
    //             </Popover.Content>
    //           </Popover>
    //         )} >
    //         <div>
    //           Download Template <FontAwesomeIcon icon={faQuestionCircle} />
    //         </div>
    //       </OverlayTrigger>
    //     ),
    //     sortable: false,
    //     resizable: false,
    //     accessor: 'download',
    //     Cell: props => {
    //       return (
    //         <div style={{ textAlign: "center" }} >
    //           <Link to={`/home/download/${props.value}`} >
    //             <Button variant="warning">Download Template</Button>
    //           </Link>
    //         </div>
    //       )
    //     }
    //   }, {
    //     disableFilters: true,
    //     disableSortBy: true,
    //     Header: () => (
    //       <OverlayTrigger
    //         placement="top"
    //         delay={{ show: 150, hide: 300 }}
    //         overlay={(
    //           <Popover id="popover-basic">
    //             <Popover.Content>
    //               Use this button to upload templates to accompany the survey.
    //             </Popover.Content>
    //           </Popover>
    //         )} >
    //         <div>
    //           Upload Templates <FontAwesomeIcon icon={faQuestionCircle} />
    //         </div>
    //       </OverlayTrigger>),
    //     sortable: false,
    //     resizable: false,
    //     accessor: 'upload',
    //     Cell: props => {
    //       return (
    //         <div style={{ textAlign: "center" }} >
    //           <Link to={`/home/uploadcso/${props.value}`} >
    //             <Button variant="secondary">Upload File</Button>
    //           </Link>
    //         </div>
    //       )
    //     }
    //   }
    ],
    []
  )

  return (
    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Container fluid className='pt-5'>
        <Card>
          <Card.Header style={{ backgroundColor: "#FBAA34", color: "white" }}>
            <h3 style={{ textAlign: 'left', float: 'left' }}>All Users &nbsp;</h3>
          </Card.Header>
          <Card.Body>
          {users && users.length > 0 ? (
            <TableContainer columns={columns} data={allUsers} />)
            : (
                <div>&nbsp;Loading all users...</div>)}
          </Card.Body>
        </Card>
      </Container>
    </Col>
  );
};

export default CsoAllUsers;
